// src/Header.js
import React from 'react';

function Header() {
  return (
    <header>
      <h1>Welcome to Calneocon '25</h1>
      <h2>31st Annual State Conference of the National Neonatology Forum, Kerala</h2>
      <p><strong>25th, 26th & 27th April, 2025 | Kozhikode</strong></p>
      <a href="/register">Register Now</a>
      <a href="#brochure">Download Brochure</a>
    </header>
  );
}

export default Header;
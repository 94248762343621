import React from 'react';

function RefundCancellation() {
  return (
    <div className="content">
      <h1>Refund & Cancellation Policy</h1>
      <p>This is the Refund & Cancellation Policy for Calneocon '25. Please read it carefully.</p>
      
      <h2>Refund Policy</h2>
      <p>We offer a full refund for cancellations made within 30 days of purchase. After 30 days, a 50% refund will be provided up to 60 days before the event. No refunds will be issued within 60 days of the event.</p>
      <ul>
        <li>Full refund within 30 days of purchase.</li>
        <li>50% refund up to 60 days before the event.</li>
        <li>No refunds within 60 days of the event.</li>
      </ul>
      
      <h2>Cancellation Policy</h2>
      <p>To cancel your registration, please contact our support team. Cancellations must be made in writing via email. We will process your cancellation within 5 business days.</p>
      <ul>
        <li>Contact support to initiate cancellation.</li>
        <li>Cancellations must be in writing via email.</li>
        <li>Processing time is 5 business days.</li>
      </ul>
      
      <h2>Contact Us</h2>
      <p>If you have any questions about this policy, please contact us at info@calneocon25.com. Our support team is available Monday through Friday, 9 AM to 5 PM.</p>
      <ul>
        <li>Email: info@calneocon25.com</li>
        <li>Support hours: Monday - Friday, 9 AM - 5 PM</li>
      </ul>
    </div>
  );
}

export default RefundCancellation;
import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="content">
      <h1>Privacy Policy</h1>
      <p>This is the privacy policy for Calneocon '25. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner.</p>
      
      <h2>Information We Collect</h2>
      <p>We collect information from you when you register on our site, subscribe to our newsletter, or fill out a form. The types of information we may collect include your name, email address, phone number, and any other details you provide.</p>
      
      <h2>How We Use Your Information</h2>
      <p>Your information helps us to better respond to your individual needs, improve our website, and enhance customer service. We may also use your information to send periodic emails regarding updates or service information.</p>
      
      <h2>How We Protect Your Information</h2>
      <p>We implement a variety of security measures to maintain the safety of your personal information. These include encryption, firewalls, and secure server hosting.</p>
      
      <h2>Sharing Your Information</h2>
      <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential.</p>
      
      <h2>Third-party Links</h2>
      <p>Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites.</p>
      
      <h2>Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, please contact us at info@calneocon25.com.</p>
    </div>
  );
}

export default PrivacyPolicy; 
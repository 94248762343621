import React from 'react';

function AboutUs() {
  return (  
    <section className="about" id="about">
      <div className="about-container">
        <h2 className="about-title">About Calneocon '25</h2>
        
        <div className="about-grid">
          <div className="welcome-message card">
            <h3 className="card-title">Dear Friends,</h3>
            <p>
              The 31st Annual State Conference of NNF Kerala, CALNEOCON '25 is 
              round the corner! Once again, Kozhikode is gearing up to welcome you.
              We look forward to meeting you at Kozhikode!
            </p>
         
            <p>
              Hosted by Neonatology Forum (NNF) Kozhikode and Indian Academy of Pediatrics (IAP) Kozhikode, Calneocon '25 promises to 
              be an enriching event. We have curated a diverse range of sessions and 
              workshops that will cater to the varied interests of PG students, practicing 
              Pediatricians and neonatologists.
            </p>
            
            <p>
              Cutting edge core neonatology topics will rub elbows with practical and 
              clinically relevant discussions as well as focused PG training sessions.
            </p>
        
            <p>Thank you for considering our invitation.</p>
            <div className="signature">
              <p>Best regards,</p>
              <p className="team">Organising Team</p>
              <p className="conference">Calneocon '25</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutUs;
// src/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h4>Contact Us</h4>
          <p>M/S.NEONATOLOGY FORUM KOZHIKODE</p>
          <p>Building No. 19/1112, Nivedyam</p>
          <p>P V Sami Road, Kozhikode</p>
          <p>Kerala, India - 673002</p>
          <a href="mailto:info@calneocon25.com">info@calneocon25.com</a>
        </div>

        <div className="footer-section">
          <h4>Quick Links</h4>
          <nav className="footer-links">
            <Link to="/terms">Terms & Conditions</Link>
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/refund-cancellation">Refund & Cancellation</Link>
          </nav>
        </div>
      </div>
      
      <div className="footer-bottom">
        <p>&copy; 2025 Calneocon '25. All Rights Reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
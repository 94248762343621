import React from 'react';
import { useNavigate } from 'react-router-dom';

function RegistrationSuccess() {
  const navigate = useNavigate();

  return (
    <div className="content">
      <div className="success-container">
        <h2>Registration Successful!</h2>
        <div className="success-message">
          <p>Thank you for registering for Calneocon '25.</p>
          <p>Your registration has been confirmed.</p>
          <p>You will receive a confirmation email shortly.</p>
        </div>
        <button 
          onClick={() => navigate('/')}
          className="home-button"
        >
          Return to Home
        </button>
      </div>
    </div>
  );
}

export default RegistrationSuccess; 
import React from 'react';

function Home() {
  return (
    <>
      <section className="about">
        <h2>About Calneocon '25</h2>
        <p>Hosted by NNF Kozhikode and IAP Kozhikode, Calneocon '25 is set to be an enriching event with cutting-edge core neonatology topics, practical clinical insights, and focused PG training sessions tailored for postgraduate students, pediatricians, and neonatologists.</p>
      </section>

      <section className="team">
        <h2>Meet the Organizing Team</h2>
        <div className="members">
          <div className="member">
            <h3>Dr. Preetha Remesh</h3>
            <p>Organizing Chairperson</p>
          </div>
          <div className="member">
            <h3>Dr. Vishnu Mohan PT</h3>
            <p>Organizing Co-Chairperson</p>
          </div>
          <div className="member">
            <h3>Dr. Nihaz Naha</h3>
            <p>Organizing Secretary</p>
          </div>
          <div className="member">
            <h3>Dr. Rahul Illaparambath</h3>
            <p>Organizing Secretary</p>
          </div>
          <div className="member">
            <h3>Dr. Shamnad M</h3>
            <p>Organizing Treasurer</p>
          </div>
        </div>
      </section>

      <section className="highlights">
        <h2>What to Expect?</h2>
        <ul>
          <li>Engaging sessions on cutting-edge neonatology topics</li>
          <li>Interactive workshops for practical learning</li>
          <li>Networking opportunities with peers and experts</li>
        </ul>
      </section>
      <section className="registration bg-gray-50 p-8 rounded-xl mb-12">
        <h2 className="text-3xl font-bold text-gray-800 mb-6 border-b pb-3">Registration Details</h2>
        <div className="bg-white shadow-lg rounded-xl overflow-x-auto">
          <table className="w-full">
            <thead style={{ backgroundColor: '#7f2d5e' }}>
              <tr>
                <th className="p-4 text-left text-sm font-semibold text-white uppercase tracking-wider">Category</th>
                <th className="p-4 text-left text-sm font-semibold text-white uppercase tracking-wider">Till Dec 20th</th>
                <th className="p-4 text-left text-sm font-semibold text-white uppercase tracking-wider">Dec 21st - Feb 15</th>
                <th className="p-4 text-left text-sm font-semibold text-white uppercase tracking-wider">Feb 16 - Apr 15</th>
                <th className="p-4 text-left text-sm font-semibold text-white uppercase tracking-wider">From Apr to Spot</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {[
                { category: "IAP & NNF MEMBER", rates: [4000, 5000, 6000, 7000] },
                { category: "IAP (OR) NNF MEMBER", rates: [5000, 6000, 7000, 8000] },
                { category: "NON- MEMBER", rates: [8000, 9000, 10000, 10000] },
                { category: "PG STUDENTS", rates: [4000, 4000, 4000, 5000] },
                { category: "ACCOMPANYING PERSON", rates: [5000, 6000, 7000, 8000] },
                { category: "CORPORATE REGISTRATION", rates: [10000, 10000, 10000, 12000] },
                { category: "NURSES REGISTRATION", rates: [3000, 3000, 3000, 1500] },
                { category: "WORKSHOP - DOCTOR", rates: [750, 750, 750, 3000] },
                { category: "WORKSHOP - NURSES", rates: [750, 750, 750, 3000] }
              ].map((row, index) => (
                <tr key={index} className="hover:bg-gray-50 transition-colors">
                  <td className="p-4 text-sm font-medium text-gray-900">{row.category}</td>
                  {row.rates.map((rate, rateIndex) => (
                    <td key={rateIndex} className="p-4 text-sm text-gray-600">₹{rate.toLocaleString()}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="mt-6 flex items-center justify-center">
          <a 
            href="/register" 
            className="inline-flex items-center px-6 py-3 text-white font-semibold rounded-lg hover:bg-gray-700 transition-colors"
            style={{ backgroundColor: '#7f2d5e', color: 'white', hover: '#662048' }}
          >
            Register Now
            <svg className="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
            </svg>
          </a>
        </div>
      </section>

      <section className="highlights bg-white p-8 rounded-xl shadow-sm mb-12">
        <h2 className="text-3xl font-bold text-gray-800 mb-6 border-b pb-3">Conference Highlights</h2>
        <ul className="grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-700">
          {[
            "Diverse range of sessions catering to PG students, practicing Pediatricians, and neonatologists",
            "Cutting-edge core neonatology topics",
            "Practical and clinically relevant discussions",
            "Focused PG training sessions",
            "Workshops for in-depth learning"
          ].map((highlight, index) => (
            <li key={index} className="flex items-start p-4 bg-gray-50 rounded-lg">
              <svg className="w-5 h-5 text-blue-600 mt-1 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              {highlight}
            </li>
          ))}
        </ul>
      </section>

      <section className="contact bg-gray-50 p-8 rounded-xl mb-12">
        <h2 className="text-3xl font-bold text-gray-800 mb-6 border-b pb-3">Contact Information</h2>
        <div className="text-gray-700 space-y-4">
          <p className="text-lg">For more details, please visit the conference website or contact the organizing team.</p>
          <div className="flex items-center justify-center space-x-6 mt-6">
            <a href="mailto:contact@calneocon25.com" className="flex items-center text-blue-600 hover:text-blue-700">
              <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
              </svg>
              Email Us
            </a>
            <a href="tel:+919876543210" className="flex items-center text-blue-600 hover:text-blue-700">
              <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
              </svg>
              Call Us
            </a>
          </div>
        </div>
      </section>

      <section className="venue">
        <h2>Venue</h2>
        <p>Calicut, Kozhikode – a city known for its rich culture and scenic beauty.</p>
        <iframe 
          src="https://www.google.com/maps/embed" 
          width="100%" 
          height="300" 
          frameBorder="0" 
          style={{ border: 0 }} 
          allowFullScreen="" 
          aria-hidden="false" 
          tabIndex="0"
        />
      </section>
    </>
  );
}

export default Home; 
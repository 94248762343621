import React, { useState, useEffect, useRef } from 'react';
import './Navbar.css';
import logo from '../assets/images/cal.png';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const drawerRef = useRef();

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        closeDrawer();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <button className="menu-toggle" onClick={toggleDrawer}>
          ☰
        </button>
        <img src={logo} alt="Calneocon Logo" className="navbar-logo" />
        <div ref={drawerRef} className={`drawer ${isOpen ? 'open' : ''}`}>
          <a href="/" onClick={closeDrawer}>Home</a>
          <a href="/aboutus" onClick={closeDrawer}>About Us</a>
          <a href="/team" onClick={closeDrawer}>Team</a>
          <a href="/highlights" onClick={closeDrawer}>Highlights</a>
          <a href="/venue" onClick={closeDrawer}>Venue</a>
          <a href="/terms" onClick={closeDrawer}>Terms</a>
        </div>
      </div>
    </nav>
  );
}

export default Navbar; 
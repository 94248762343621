import React from 'react';

function TermsAndConditions() {
  return (
    <div className="content">
      <h1>Terms and Conditions</h1>
      <p>Welcome to Calneocon '25. These terms and conditions outline the rules and regulations for the use of our website.</p>
      
      <h2>1. Introduction</h2>
      <p>By accessing this website, we assume you accept these terms and conditions. Do not continue to use Calneocon '25 if you do not agree to all of the terms and conditions stated on this page.</p>
      
      <h2>2. Cookies</h2>
      <p>We employ the use of cookies. By accessing Calneocon '25, you agreed to use cookies in agreement with our privacy policy.</p>
      
      <h2>3. License</h2>
      <p>Unless otherwise stated, Calneocon '25 and/or its licensors own the intellectual property rights for all material on Calneocon '25. All intellectual property rights are reserved.</p>
      
      <h2>4. User Comments</h2>
      <p>Parts of this website offer an opportunity for users to post and exchange opinions and information. Calneocon '25 does not filter, edit, publish or review Comments prior to their presence on the website.</p>
      
      <h2>5. Hyperlinking to our Content</h2>
      <p>The following organizations may link to our Website without prior written approval: Government agencies, Search engines, News organizations, etc.</p>
      
      <h2>6. Content Liability</h2>
      <p>We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are rising on your Website.</p>
      
      <h2>7. Your Privacy</h2>
      <p>Please read our Privacy Policy.</p>
      
      <h2>8. Reservation of Rights</h2>
      <p>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request.</p>
      
      <h2>9. Removal of links from our website</h2>
      <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment.</p>
      
      <h2>10. Disclaimer</h2>
      <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website.</p>
    </div>
  );
}

export default TermsAndConditions; 